import React from "react";
import Layout from "../components/layout";
import SEO from "../components/seo";
import { ContactHeader, BannerDefault, Title, Section } from "../utils/";
import contactImg from "../utils/img/bcg/contactBcg.jpg";
import QuotingForm from "../components/06-contact/QuotingForm";
import { graphql, StaticQuery } from "gatsby";

const QuotePage = ({
  dtitle,
  dsubtitle,
  dsectionTitle1,
  dsectionDescription1
}) => (
  <Layout>
    <SEO
      title="XBound"
      keywords={[
        `smarta kontrakt sverige svenskt företag`,
        ` svenskt företag blockchain utveckling sverige`,
        `Blockchain development smarta kontrakt och blockchain utveckling`,
        `Smart Contract development `,
        `Ethereum Development sverige eterumutveckling `,
        `Solidity Development svenskt företag `,
        `Hyperledger Development Sweden`,
        `ICO STO Development Sweden Stockholm Malmo`,
        `Smart contract Audit Sweden smart kontraktskontroll`,
        `Blockchain Consulting and Development Sweden Stockholm Malmo`,
        `Full-Stack Development Sweden Stockholm Malmo`,
        `svensk blockchain utveckling sverige`,
        `svensk blockchain smart kontraktsutveckling`,
        `Full-Stack Development services sverige`,
        `blockchain company Sweden`,
        `ERC20 Ethereum Token Development Sweden`,
        `Swedish Ethereum Development ICO`,
        `gatsby smarta kontrakt och blockchain utveckling sverige`,
        `react sverige svenskt företag `,
        `Nodejs sverige svenskt företag `,
        `JAMStack Swedish Sweden Stockholm Malmo`,
        `Gatsby Website development Sweden`,
        `React Redux Graphql Front developer svenskt företag `,
        `React Redux Graphql Backend development Sweden Stockholm Malmo`,
        `Cryptocurency development Sweden`,
        `Wallet development Cryptocurency Smart Contract`,
        `Website development Sweden`,
        `Website blog hemsida utveckling Sweden Stockholm Malmo`,
        `Website blog hemsida utveckling svenskt företag`
      ]}
    />
    <StaticQuery
      query={QuoteQUERY}
      render={data => {
        const quoteData = data.quotePageDate.edges[0];
        const {
          title,
          subtitle,
          sectionTitle1,
          sectionDescription1
        } = quoteData.node;
        return (
          <div>
            <ContactHeader img={contactImg}>
              {/* <ContactHeader> */}
              <BannerDefault
                title={title || dtitle}
                subtitle={subtitle.subtitle || dsubtitle}
              />
            </ContactHeader>
            <Section>
              <Title
                message={
                  sectionDescription1.sectionDescription1 ||
                  dsectionDescription1
                }
                title={sectionTitle1 || dsectionTitle1}
              />
            </Section>
            <QuotingForm />
          </div>
        );
      }}
    />
  </Layout>
);

const QuoteQUERY = graphql`
  {
    quotePageDate: allContentfulQuotePage {
      edges {
        node {
          title
          subtitle {
            subtitle
          }
          sectionTitle1
          sectionDescription1 {
            sectionDescription1
          }
        }
      }
    }
  }
`;

QuotePage.defaultProps = {
  dhTitle: "Request a Quote_",
  dhSubTitle:
    "Wondering how can we help? Find out how we can take your business to the next level. Simply fill in your project's details below and we'll do our best to get back to you within 1 - 2 business days._",
  dsectionTitle1: "Get a Quote_",
  dsectionSubTitle1:
    "Thanks for contacting us. Please tell us more about your upcoming project. Simply fill in your details for your free, no obligation quotation, and click Request Quote. We will try to get back to you as soon as possible._"
};

export default QuotePage;
