/**
 * Source: https://www.templatemonster.com/blog/responsive-css3-form-fields-tutorial/
 */
import React from "react";
import styled from "styled-components";
import { styles } from "../../utils";
// import { navigateTo } from "gatsby-link";
// import { MdLocationOn, MdLocalPhone, MdMailOutline } from "react-icons/md";
function encode(data) {
  const formData = new FormData();

  for (const key of Object.keys(data)) {
    formData.append(key, data[key]);
  }

  return formData;
}

class QuotingForm extends React.Component {
  constructor() {
    super();
    this.state = {
      fields: {},
      errors: {},
      serviceOptions: [
        "Blockchain Consulting",
        "Smart Contract development",
        "Smart Contract Audit",
        "Dapp Development",
        "Hire Blockchain consultant(s)",
        "ICO and STO",
        "MVP development",
        "Blockchain Integration",
        "Private Blockchain - Hyperledger",
        "Public Blockchain - Solidity",
        "Blockchain Courses and Seminars",
        "Others"
      ]
    };

    this.handleChange = this.handleChange.bind(this);
    this.submituserRegistrationForm = this.submituserRegistrationForm.bind(
      this
    );
  }

  handleChange(e) {
    let fields = this.state.fields;
    let name = e.target.name;
    let value = e.target.value;
    fields[name] = value;
    // console.log(fields);
    this.setState(
      {
        fields
      },
      () => {
        this.validateField(name, value);
      }
    );
  }
  handleAttachment = e => {
    let fields = this.state.fields;
    let name = e.target.name;
    let value = e.target.files[0];
    fields[name] = value;

    this.setState({ fields });
  };

  handleSubmit = form => {
    // e.preventDefault();
    // const form = e.target;
    fetch("/", {
      method: "POST",
      body: encode({
        "form-name": form.getAttribute("name"),
        ...this.state.fields
      })
    })
      // .then(() => navigateTo(form.getAttribute("action")))
      .catch(error => alert(error));
  };

  submituserRegistrationForm(e) {
    e.preventDefault();
    const form = e.target;
    if (this.validateForm()) {
      let fields = {};
      fields["username"] = "";
      fields["company"] = "";
      fields["emailid"] = "";
      fields["mobileno"] = "";
      fields["message"] = "";
      fields["myfile"] = "";
      // fields["password"] = "";
      this.setState({ fields: fields });
      this.handleSubmit(form);
      // alert("Quote Request Submitted");
    }
  }

  validateField(fieldName, value) {
    let fields = this.state.fields;
    let errors = {};
    let formIsValid = this.state.formIsValid;

    switch (fieldName) {
      case "username":
        if (!value) {
          formIsValid = false;
          errors["username"] = "*Please enter your username.";
        }

        if (typeof value !== "undefined") {
          if (!fields["username"].match(/^[a-zA-Z ]*$/)) {
            formIsValid = false;
            errors["username"] = "*Please enter alphabet characters only.";
          }
        }
        break;
      case "emailid":
        if (!value) {
          formIsValid = false;
          errors["emailid"] = "*Please enter your email-ID.";
        } else if (typeof value !== "undefined") {
          //regular expression for email validation
          var pattern = new RegExp(
            /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i
          );
          if (!pattern.test(value)) {
            formIsValid = false;
            errors["emailid"] = "*Please enter valid email-ID.";
          }
        }
        // else formIsValid = true;
        break;
      case "mobileno":
        if (!value) {
          formIsValid = false;
          errors["mobileno"] = "*Please enter your mobile no.";
        }

        if (typeof value !== "undefined") {
          // if (!value.match(/^[0-9]{10}$/)) { to enforce only 10 numbers
          // if (!value.match(/^[0-9]/)) {
          //   formIsValid = false;
          //   errors["mobileno"] = "*Please enter valid mobile no.";
          // }
        }
        break;
      case "password":
        if (!value) {
          formIsValid = false;
          errors["password"] = "*Please enter your password.";
        }
        if (typeof value !== "undefined") {
          if (
            !value.match(
              /^.*(?=.{8,})(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[@#$%&]).*$/
            )
          ) {
            formIsValid = false;
            errors["password"] = "*Please enter secure and strong password.";
          }
        }
        break;
      default:
        break;
    }
    this.setState({
      errors: errors,
      isFormValid: formIsValid
    });
    return formIsValid;
  }
  validateForm() {
    let fields = this.state.fields;
    let errors = {};
    let formIsValid = true;

    if (!fields["username"]) {
      formIsValid = false;
      errors["username"] = "*Please enter your name.";
    }

    if (typeof fields["username"] !== "undefined") {
      if (!fields["username"].match(/^[a-zA-Z ]*$/)) {
        formIsValid = false;
        errors["username"] = "*Please enter alphabet characters only.";
      }
    }

    if (!fields["emailid"]) {
      formIsValid = false;
      errors["emailid"] = "*Please enter your email.";
    }

    if (typeof fields["emailid"] !== "undefined") {
      //regular expression for email validation
      var pattern = new RegExp(
        /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i
      );
      if (!pattern.test(fields["emailid"])) {
        formIsValid = false;
        errors["emailid"] = "*Please enter a valid email address.";
      }
    }

    if (!fields["mobileno"]) {
      formIsValid = false;
      errors["mobileno"] = "*Please enter your mobile number.";
    }

    if (typeof fields["mobileno"] !== "undefined") {
      // if (!fields["mobileno"].match(/^[0-9]{10}$/)) {
      //   formIsValid = false;
      //   errors["mobileno"] = "*Please enter a valid mobile number.";
      // }
    }

    if (formIsValid) {
      errors["message"] =
        "Thanks for contacting us, your message has been sent.";
    }

    // if (!fields["password"]) {
    //   formIsValid = false;
    //   errors["password"] = "*Please enter your password.";
    // }

    // if (typeof fields["password"] !== "undefined") {
    //   if (
    //     !fields["password"].match(
    //       /^.*(?=.{8,})(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[@#$%&]).*$/
    //     )
    //   ) {
    //     formIsValid = false;
    //     errors["password"] = "*Please enter secure and strong password.";
    //   }
    // }

    this.setState({
      errors: errors
    });
    return formIsValid;
  }

  render() {
    return (
      <RegisterWrapper>
        <div id="wrapper">
          <h3>Get An Estimated Quote</h3>
          <form
            name="QuotingForm"
            method="post"
            data-netlify="true"
            data-netlify-honeypot="bot-field"
            onSubmit={this.submituserRegistrationForm}
          >
            {/* You still need to add the hidden input with the form name to your JSX form */}
            <input
              type="hidden"
              name="form-name"
              value="userRegistrationForm"
            />
            <p hidden>
              <label>
                Don’t fill this out:{" "}
                <input name="bot-field" onChange={this.handleChange} />
              </label>
            </p>
            <div className="col-2">
              <label>
                Name
                <input
                  placeholder="What is your full name?"
                  name="username"
                  id="name"
                  tabIndex={1}
                  value={this.state.fields.username}
                  onChange={this.handleChange}
                />
              </label>
            </div>
            <div className="col-2">
              <label>
                Company
                <input
                  placeholder="Your organization name?"
                  name="company"
                  id="company"
                  tabIndex={2}
                  value={this.state.fields.company}
                  onChange={this.handleChange}
                />
              </label>
            </div>
            <div className="col-3">
              <label>
                Phone Number
                <input
                  placeholder="e.g +46111111111"
                  id="mobileno"
                  tabIndex={3}
                  name="mobileno"
                  value={this.state.fields.mobileno}
                  onChange={this.handleChange}
                />
              </label>
            </div>
            <div className="col-3">
              <label>
                Email
                <input
                  placeholder="What is your e-mail address?"
                  id="email"
                  name="emailid"
                  value={this.state.fields.emailid}
                  onChange={this.handleChange}
                  tabIndex={4}
                />
              </label>
            </div>
            <div className="col-3">
              <label>
                Service Required
                <select
                  className="service-select"
                  tabIndex={5}
                  name="selectservice"
                  value={this.state.fields.service}
                  placeholder="Service you are looking for"
                  onChange={this.handleChange}
                >
                  <option value hidden>
                    Service you are looking for
                  </option>
                  {this.state.serviceOptions.map(option => {
                    return (
                      <option key={option} value={option}>
                        {option}
                      </option>
                    );
                  })}
                </select>
              </label>
            </div>
            <div className="col-4">
              <label>
                Description
                <textarea
                  placeholder="Tell us about your project"
                  id="message"
                  tabIndex={6}
                  name="message"
                  rows={10}
                  value={this.state.fields.message}
                  onChange={this.handleChange}
                />
              </label>
            </div>
            <div className="col-5">
              <label>
                <input
                  type="file"
                  name="myfile"
                  placeholder="Upload a requirment file"
                  onChange={this.handleAttachment}
                />
              </label>
            </div>
            <div className="errorMsg">
              <div className="errorMsg1">{this.state.errors.emailid}</div>
              <div className="errorMsg1">{this.state.errors.mobileno}</div>
              <div className="confirmMsg">{this.state.errors.message}</div>
            </div>
            <div className="col-submit">
              <button className="submitbtn" type="submit">
                Request Quote
              </button>
            </div>
          </form>
        </div>
      </RegisterWrapper>
    );
  }
}

/************************************/
/** RegisterWrapper Styled Component */
/************************************/
const RegisterWrapper = styled.section`
  margin: 0rem 0rem 1.2rem;
  padding: 2rem;
  /* vertical-align: baseline; */
  font-family: Arial, Tahoma, sans-serif;
  /* background: #f1f1f1; */
  /* background: #e2eef4; */
  font-size: 62.5%;
  line-height: 1;
  padding-top: 40px;
  outline: none;
  -webkit-font-smoothing: antialiased;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  box-sizing: border-box;
  #wrapper {
    /* width: 300px; */
    background: ${styles.colors.mainWhite};
    box-shadow: 3px 4px 40px -9px rgba(0, 0, 0, 0.7);
    /* border: 1px solid #d6d7da; */
    padding: 1rem 1rem 2rem 1rem;
    border-radius: 5px;
    /* border-top-right-radius: 10px; */
    line-height: 10px;
    /* color: #333333; */
    font-size: 12px;
  }
  br {
    display: block;
    line-height: 1.6em;
  }

  input,
  textarea {
    -webkit-font-smoothing: antialiased;
    outline: none;
  }
  .errorMsg {
    width: 100%;
    font-size: 0.7rem;
    color: #cc0000;
    text-align: center;
    /* margin-left: 2rem; */
    /* margin-bottom: 12px; */
  }
  .confirmMsg {
    width: 100%;
    font-size: 0.9rem;
    color: green;
    text-align: center;
    margin-top: 1rem;
    /* margin-left: 2rem; */
    /* margin-bottom: 12px; */
  }
  strong,
  b {
    font-weight: bold;
  }
  em,
  i {
    font-style: italic;
  }

  /** form field **/
  form {
    display: block;
    margin: 30px;
    overflow: hidden;
    background: #fff;
    border: 1px solid #e4e4e4;
    border-radius: 5px;
    font-size: 0;
  }

  form > div > label {
    display: block;
    padding: 20px 20px 10px;
    /* vertical-align: top; */
    font-size: 12px;
    font-weight: bold;
    text-transform: uppercase;
    color: #939393;
    cursor: pointer;
  }
  form > div.switch > label {
    padding: 16px 20px 13px;
  }

  .col-2,
  .col-3,
  .col-4 {
    border-bottom: 1px solid #e4e4e4;
  }

  form > div > .col-4 {
    height: 86px;
  }

  label > input,
  label > textarea {
    display: inline-block;
    position: relative;
    width: 100%;
    height: 27px;
    line-height: 27px;
    margin: 5px -5px 0;
    padding: 7px 5px 3px;
    border: none;
    outline: none;
    color: #555;
    font-family: "Helvetica Neue", Arial, sans-serif;
    font-weight: bold;
    font-size: 14px;
    opacity: 0.6;
    transition: all linear 0.3s;
  }
  label > textarea {
    height: 100px;
  }
  .col-submit {
    text-align: center;
    padding: 10px;
  }
  .col-5 > label {
    padding-top: 0.4rem;
  }
  .col-5 > label > input {
    font-size: 0.8rem;
    line-height: 0.8rem;
    height: 24px;
    margin: 0rem;
    border: none;
  }
  label > select {
    display: block;
    width: 100%;
    padding: 0;
    color: #555;
    /* margin: 8px 0 0px; */
    height: 33px;
    /* line-height: 27px; */
    margin: 5px -5px 0;
    /* padding: 7px 5px 3px; */

    font-weight: 500;
    background: transparent;
    border: none;
    outline: none;
    font-family: "Helvetica Neue", Arial, sans-serif;
    font-size: 14px;
    opacity: 0.4;
    transition: all linear 0.3s;
  }

  label > input:focus,
  label > select:focus {
    opacity: 1;
  }

  /** button design based on http://codepen.io/guvootes/pen/eyDAb **/
  button {
    width: 100%;
    height: 35px;
    border: none;
    border-radius: 4px;
    margin: 0 0 15px 0;
    font-size: 14px;
    color: #fff;
    font-weight: bold;
    text-shadow: 1px 1px 0 rgba(0, 0, 0, 0.3);
    overflow: hidden;
    outline: none;
  }

  button.submitbtn {
    background-image: linear-gradient(#1476f2, #147fff);
    border-bottom: 1px solid #648c3a;
    cursor: pointer;
    color: #fff;
  }
  button.submitbtn:hover {
    background-image: linear-gradient(#147fff, #1476f2);
  }
  button.submitbtn:active {
    height: 34px;
    border-bottom: 0;
    margin: 1px 0 0 0;
    background-image: linear-gradient(#147fff, #1476f2);
    box-shadow: inset 0 1px 3px 1px rgba(0, 0, 0, 0.3);
  }
  h3 {
    display: block;
    font-size: 2em;
    line-height: 1.45em;
    font-family: "Laila", serif;
    text-align: center;
    font-weight: bold;
    color: #555;
    text-shadow: 1px 1px 0 #fff;
    text-transform: uppercase;
  }

  /* Responsive design */
  @media (max-width: 849px) {
    /* margin: 1rem 0rem;
    padding:1rem; */
    h3 {
      font-size: 1.4rem;
    }
  }
  /** responsive design **/
  @media (min-width: 850px) {
    padding: 5rem;
    form > div {
      display: inline-block;
    }
    .col-submit {
      display: block;
    }

    .col-2,
    .col-3,
    .col-4 {
      box-shadow: 1px 1px #e4e4e4;
      border: none;
    }
    .col-3-1 {
      background: red;
    }
    .col-2 {
      width: 50%;
    }
    .col-3 {
      width: 33.3333333333%;
    }
    .col-4 {
      width: 100%;
    }
    .col-5 {
      width: 100%;
      /* height:20px; */
    }
    .col-submit button {
      width: 30%;
      margin: 0 auto;
    }
    h3 {
      font-size: 2em;
    }
  }
`;

export default QuotingForm;
